import { round } from "lodash";

import type { IndicatorType } from "~/lib/genu";

export const haToSqM = 10000;
export const sqMToHa = 1 / haToSqM;

// an area already in ha
export const formatArea = (area: number): string => {
  return `${formatNumber(area)} ha`;
};

// a distance in meters
export const formatDistance = (distance: number): string => {
  if (distance > 5000) return `${(distance / 1000).toFixed(0)} km`;
  if (distance > 1000) return `${(distance / 1000).toFixed(1)} km`;
  return `${distance.toFixed(0)} m`;
};

export const roundPercentage = (value: number): number => {
  const percent = 100 * value;
  return round(percent, percent > 0.5 || percent === 0 ? 0 : 1);
};

export const formatPercentageNumber = (value: number, locale?: string): string => {
  const percent = roundPercentage(value);
  return isNaN(percent) ? "--" : new Intl.NumberFormat(locale ?? "en-US").format(percent);
};

export const formatProportion = (value: number, locale?: string): string => {
  return `${formatPercentageNumber(value, locale)}%`;
};

export const formatLocation = (location: GeoJSON.Point, precision = 5) => {
  return `Lon ${location.coordinates[0].toFixed(precision)} Lat ${location.coordinates[1].toFixed(
    precision
  )}`;
};

export const formatAddress = ({
  address,
  postalCode,
  locality,
  country,
}: {
  address: string | null;
  postalCode: string | null;
  locality: string | null;
  country: string | null;
}) => {
  return [address, `${postalCode ?? ""} ${locality ?? ""}`, country]
    .map((value) => value?.trim())
    .filter((value) => !!value)
    .join(", ");
};

export const formatRating = (rating: number) => {
  return `${Math.round(rating)}`;
};

export const formatNumberWithSign = (rating: number) => {
  const rounded = Math.round(rating);
  if (isNaN(rounded)) {
    return "--";
  } else if (rounded > 0) {
    return `+${rounded}`;
  } else if (rounded === 0) {
    return "0";
  } else {
    return rounded.toString();
  }
};

export const formatNumber = (value: number, locale?: string): string => {
  return isNaN(value)
    ? "--"
    : value >= 1e5
      ? value.toExponential(2)
      : new Intl.NumberFormat(locale ?? "en-US").format(
          round(value, value > 10 ? 0 : value > 1 ? 1 : value === 0 ? 0 : 2)
        );
};

export const humanReadableFormatNumber = (value: number, locale?: string): string => {
  if (isNaN(value)) {
    return "--";
  }

  let suffix = "";
  if (value >= 1e9) {
    suffix = "Mds"; // @todo translate
    value = round(value / 1e9, value >= 1e11 ? 0 : 1);
  } else if (value >= 1e6) {
    suffix = "M";
    value = round(value / 1e6, value >= 1e8 ? 0 : 1);
  } else if (value >= 1e3) {
    suffix = "k";
    value = round(value / 1e3, value >= 1e5 ? 0 : 1);
  } else {
    value = round(value, value > 10 ? 0 : value > 1 ? 1 : value === 0 ? 0 : 2);
  }

  return `${new Intl.NumberFormat(locale ?? "en-US").format(value)}${suffix}`;
};

export const formatDecimalNumber = (value: number, locale?: string, precision = 0) =>
  new Intl.NumberFormat(locale ?? "en-US").format(round(value, precision));

export const formatShortHsuCode = (code: string): string => {
  const parts = code.split(/[._]/);
  return parts[parts.length - 1];
};

export function splitNormalized(value: IndicatorType): IndicatorType;
export function splitNormalized(value: string): string;
export function splitNormalized(value: string) {
  return value.endsWith("_normalized") ? value.slice(0, -"_normalized".length) : value;
}

export const addNormalized = (value: string) => {
  return value.endsWith("_normalized") ? value : `${value}_normalized`;
};

export const unaccent = (str: string) => {
  /// see https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  return str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
};

export const ellide = (str: string, length: number, suffix = ".") => {
  return str.length <= length ? str : `${str.substring(0, Math.max(1, length))}${suffix}`;
};
